@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,
 600, 600i, 700, 800i);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --black: #323232;
  --darkblue: #015488;
  --lightblue: #26b2e7;
  --bluehover: #1d8bb5;
  --white: #ffffff;
  --orange: #e49638;
  --maroon: #8e153e;
  --greyblue: #becedc;
  --darkgrey: #707070;
  --grey: #c4c4c4;
  --lightgrey: #eeeeee;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}
* {
    box-sizing: content-box;
    box-sizing: padding-box;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
}

/* Crow default styles */

.crow-form-page {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 30px 100px 30px;
}

.crow-form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@media (min-width: 992px) {

  .crow-form-group {
    width: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 60px 0 0;
  }

}

.crow-form {
  padding: 0;
  font-family: "Open Sans" , sans-serif;
  padding: 0 0 100px 0;
}

.crow-form h1 {
  width: 100%;
  padding: 30px 0 0 0;
}

.crow-form h2 {
  width: 100%;
  padding: 30px 0 0 0;
}

.crow-field {
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 30px 0 30px 0;
}

.crow-field input {
  font-family: "Open Sans", sans-serif;
}

.crow-field label {
  width: 100%;
  font-family: "Open Sans" , sans-serif;
  font-weight: 600;
  color: #323232;
  color: var(--black);
  padding: 0 0 15px 0;
}
.crow-field span {
  font-family: "Open Sans" , sans-serif;
  font-weight: 600;
}

/* Invalid styles */

.crow-field.invalid label:first-child {
  color: red !important;
}

.crow-field.invalid input[type="text"], .crow-field.invalid input[type="checkbox"] {
  border: 1px solid red !important;
  color: red !important;
}

/* Disabled styles */

.crow-field.disabled {
  cursor: not-allowed !important;
}

.crow-field.disabled label:first-child {
  color: #eeeeee !important;
  color: var(--lightgrey) !important;
  pointer-events: none !important;
}

.crow-field.disabled .on-off::before, .crow-field.disabled .semantic_ui_range_inner div:nth-child(2)  {
  background-color: #eeeeee !important;
  background-color: var(--lightgrey) !important;
  pointer-events: none !important;
}

.crow-field.disabled input[type="text"], .crow-field.disabled input[type="checkbox"],
.crow-field.disabled .on-off::before,  .crow-field.disabled .daypicker label,
.crow-field.disabled .themepicker label,
.crow-field.disabled .timeintervalpicker label, .crow-field.disabled .semantic_ui_range_inner div:nth-child(3), .crow-field.disabled .semantic_ui_range_inner div:nth-child(4), .crow-field.disabled .numberPicker button {
  border: 1px solid #eeeeee !important;
  border: 1px solid var(--lightgrey) !important;
  color: #eeeeee !important;
  color: var(--lightgrey) !important;
  pointer-events: none !important;
}

.crow-field.disabled .timeslider-labels .ui.label, .crow-field.disabled .timeslider-labels .ui.label span, .crow-field.disabled .numberPicker span, .crow-field.disabled .sixteen.wide.column div {
  color: #eeeeee !important;
  color: var(--lightgrey) !important;
  pointer-events: none !important;
}

.crow-form .btn-container {
  position: fixed;
  z-index: 1000;
  width: 100%;
  bottom: 0;
  background-color: #eeeeee;
  background-color: var(--lightgrey);
  padding: 30px;
  border-top: 2px solid #c4c4c4;
  border-top: 2px solid var(--grey);
}

.crow-form .btn {
  height: 45px;
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  font-weight: 600;
  background-color: #26b2e7;
  background-color: var(--lightblue);
  color: #ffffff;
  color: var(--white);
  border: 0px;
  margin: 30px 0 0 -;
  padding: 0 30px 0 30px;
  cursor: pointer;
  -webkit-transition: background-color ease 0.3s;
  transition: background-color ease 0.3s;
}

.crow-form .btn:hover {
  background-color: #1d8bb5;
  background-color: var(--bluehover);
  border: 0px;
}

.crow-form .btn:disabled {
  background-color: #eeeeee;
  background-color: var(--lightgrey);
  border: 0px;
}

.crow-form .btn:disabled:hover {
  cursor: not-allowed;
}

.crow-form .btn-secondary {
  font-size: 1em;
  background-color: #ffffff;
  background-color: var(--white);
  color: #015488;
  color: var(--darkblue);
  border: 3px solid #015488;
  border: 3px solid var(--darkblue);
  margin: 30px 0 0 0;
  padding: 12px 30px 12px 30px;
  cursor: pointer;
  -webkit-transition: background-color ease 0.3s;
  transition: background-color ease 0.3s;
}

.crow-form .btn-secondary:hover {
  background-color: #015488;
  background-color: var(--darkblue);
  color: #ffffff;
  color: var(--white);
  border: 3px solid #015488;
  border: 3px solid var(--darkblue);
}

/* ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--grey);
}

::-webkit-scrollbar-thumb {
  background: var(--darkgrey);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--black);
} */

/* On/Off switch */

input[type="checkbox"] {
  border: 1px solid #707070;
  border: 1px solid var(--darkgrey);
}

input[type="checkbox"]:hover {
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
}

input[type="checkbox"]:focus {
  outline: 3px solid #26b2e7;
  outline: 3px solid var(--lightblue);
}

.on-off {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 74px;
  height: 42.5px;
  display: inline-block;
  position: relative;
  background-color: #ffffff;
  background-color: var(--white);
  border: none;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  -webkit-transition: background-color ease 0.3s;
  transition: background-color ease 0.3s;
}

.on-off:before {
  content: "on off";
  display: block;
  position: absolute;
  z-index: 2;
  width: 33px;
  height: 33px;
  left: 4px;
  top: 4px;
  font: 10px/33px sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  text-indent: -22px;
  word-spacing: 45px;
  white-space: nowrap;
  background: #015488;
  background: var(--darkblue);
  color: #015488;
  color: var(--darkblue);
  border-radius: 0px;
  -webkit-transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

.on-off:checked {
  background-color: #015488;
  background-color: var(--darkblue);
}

.on-off:checked:before {
  left: 35px;
  background-color: #ffffff;
  background-color: var(--white);
  color: #ffffff;
  color: var(--white);
}

/* Text Input */

input[type="text"] {
  width: 100%;
  font-size: 1em;
  line-height: 1.5em;
  color: #323232;
  color: var(--black);
  padding: 10px;
  border-radius: 0px;
  border: 1px solid #707070;
  border: 1px solid var(--darkgrey);
  outline: 0;
}

input[type="text"]:hover {
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
}

input[type="text"]:focus {
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
  outline: 4px solid #26b2e7;
  outline: 4px solid var(--lightblue);
}
/* Textarea */

textarea {
  width: 100%;
  font-size: 1em;
  line-height: 1.5em;
  color: #323232;
  color: var(--black);
  height: 6em;
  padding: 10px;
  border-radius: 0px;
  border: 1px solid #707070;
  border: 1px solid var(--darkgrey);
  outline: 0;
  resize: none;
}

textarea:hover {
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
}

textarea:focus {
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
  outline: 4px solid #26b2e7;
  outline: 4px solid var(--lightblue);
}

/* Radio Toggle */

/* Daypicker */

.daypicker {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

li {
  margin: 0 5px 0 5px;
  flex-grow: 1;
  flex-basis: 0;
}

li:first-child {
  margin: 0 5px 0 0;
}

li:last-child {
  margin: 0 0 0 5px;
}

.daypicker label {
  z-index: 1;
  height: 42.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  line-height: 1.5em;
  color: #707070;
  color: var(--darkgrey);
  padding: 0;
  cursor: pointer;
  -webkit-transition: background-color ease 0.3s;
  transition: background-color ease 0.3s;
}

.daypicker input {
  z-index: -1;
  position: absolute;
  opacity: 0;
}

.daypicker input[type=checkbox] + label {
  background-color: #ffffff;
  background-color: var(--white);
  border: 1px solid #707070;
  border: 1px solid var(--darkgrey);
}

.daypicker input[type=checkbox]:hover + label{
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
}

.daypicker input[type=checkbox]:focus + label {
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
  outline: 3px solid #26b2e7;
  outline: 3px solid var(--lightblue);
}

.daypicker input[type=checkbox]:checked + label {
  background: #015488;
  background: var(--darkblue);
  color: #ffffff;
  color: var(--white);
}

/* Themepicker */

.themepicker {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.themepicker li {
  margin: 0 5px 0 5px;
  flex-grow: 1;
  flex-basis: 0;
}

.themepicker li:first-child {
  margin: 0 5px 0 0;
}

.themepicker li:last-child {
  margin: 0 0 0 5px;
}

.themepicker label {
  z-index: 1;
  height: 42.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  line-height: 1.5em;
  color: #707070;
  color: var(--darkgrey);
  padding: 0;
  cursor: pointer;
  -webkit-transition: background-color ease 0.3s;
  transition: background-color ease 0.3s;
}

.themepicker input {
  z-index: -1;
  position: absolute;
  opacity: 0;
}

.themepicker input[type=radio] + label {
  background-color: #ffffff;
  background-color: var(--white);
  border: 1px solid #707070;
  border: 1px solid var(--darkgrey);
}

.themepicker input[type=radio]:hover + label{
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
}

.themepicker input[type=radio]:focus + label {
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
  outline: 3px solid #26b2e7;
  outline: 3px solid var(--lightblue);
}

.themepicker input[type=radio]:checked + label {
  background: #015488;
  background: var(--darkblue);
  color: #ffffff;
  color: var(--white);
}

/* Timeintervalpicker */

.timeintervalpicker {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.timeintervalpicker li {
  margin: 0 5px 0 5px;
  flex-grow: 1;
  flex-basis: 0;
}

.timeintervalpicker li:first-child {
  margin: 0 5px 0 0;
}

.timeintervalpicker li:last-child {
  margin: 0 0 0 5px;
}

.timeintervalpicker label {
  z-index: 1;
  height: 42.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  line-height: 1.5em;
  color: #707070;
  color: var(--darkgrey);
  padding: 0;
  cursor: pointer;
  -webkit-transition: background-color ease 0.3s;
  transition: background-color ease 0.3s;
}

.timeintervalpicker input {
  z-index: -1;
  position: absolute;
  opacity: 0;
}

.timeintervalpicker input[type=radio] + label {
  background-color: #ffffff;
  background-color: var(--white);
  border: 1px solid #707070;
  border: 1px solid var(--darkgrey);
}

.timeintervalpicker input[type=radio]:hover + label{
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
}

.timeintervalpicker input[type=radio]:focus + label {
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
  outline: 3px solid #26b2e7;
  outline: 3px solid var(--lightblue);
}

.timeintervalpicker input[type=radio]:checked + label {
  background: #015488;
  background: var(--darkblue);
  color: #ffffff;
  color: var(--white);
}

/* Timeslider */

.ui.grid {
  width: 100% !important;
  max-width: 350px !important;
  margin: 0 !important;
  padding: 0 !important;
  margin: 0 0 -20px 0;
}

.sixteen.wide.column {
  padding: 0 !important;
}

.sixteen.wide.column div:first-child {
  padding: 0 !important;
}

.semantic_ui_range_inner {
  margin: 0 !important;
}

.semantic_ui_range_inner div:first-child {
  background-color: #eeeeee !important;
  background-color: var(--lightgrey) !important;border-radius: 0 !important;
}

.semantic_ui_range_inner div:nth-child(2) {
  background-color: #015488 !important;
  background-color: var(--darkblue) !important;
  border-radius: 0 !important;
}

.semantic_ui_range_inner div:nth-child(3), .semantic_ui_range_inner div:nth-child(4) {
  background-color: #ffffff !important;
  background-color: var(--white) !important;
  border: 1px solid #707070;
  border: 1px solid var(--darkgrey);
  border-radius: 0 !important;
  box-shadow: none !important;
}

.semantic_ui_range_inner div:nth-child(3):hover, .semantic_ui_range_inner div:nth-child(4):hover {
  background-color: #ffffff !important;
  background-color: var(--white) !important;
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
  border-radius: 0 !important;
  cursor: -webkit-grab;
  cursor: grab;
}

.semantic_ui_range_inner div:nth-child(3):active, .semantic_ui_range_inner div:nth-child(4):active {
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
  outline: 3px solid #26b2e7 !important;
  outline: 3px solid var(--lightblue) !important;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.timeslider-labels {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 0 0;
}

.ui.label {
  width: 200px !important;
  background-color: transparent !important;
  color: #015488 !important;
  color: var(--darkblue) !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 1.5em !important;
  font-weight: 600 !important;
  margin: 0 0 -25px 0 !important;
  padding: 0 !important;
}

.ui.label:nth-child(2) {
  text-align: right;
}

.ui.label span {
  color: #707070 !important;
  color: var(--darkgrey) !important;
  font-size: .5em !important;  
}

/* Increaser/Decreaser */

.numberPicker {
  width: auto;
  max-width: 350px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.numberPicker button {
  background-color: #ffffff;
  background-color: var(--white);
  color: #707070;
  color: var(--darkgrey);
  border: 1px solid #707070;
  border: 1px solid var(--darkgrey);
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}

.numberPicker button:hover {
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
}

.numberPicker button:active {
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
  outline: 3px solid #26b2e7;
  outline: 3px solid var(--lightblue);
}

.numberPicker span {
  font-size: 1.5em;
  color: #015488;
  color: var(--darkblue);
  padding: 0 15px 0 15px;
}

/* Custom switch */

.customSwitch {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.customSwitch div {
  margin: 0 5px 0 5px;
  flex-grow: 1;
  flex-basis: 0;
  width: 176px;
}

.customSwitch div:first-child {
  margin: 0
}

.customSwitch div:last-child {
  margin: 0 0 0 -2px;
}

.customSwitch label {
  z-index: 1;
  height: 42.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  -webkit-transition: background-color ease 0.3s;
  transition: background-color ease 0.3s;
}

.customSwitch label span {
  width: 125px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  font-size: 1em;
  line-height: 1.5em;
  color: #707070;
  color: var(--darkgrey);
  text-align: center;
}

.customSwitch input {
  z-index: -1;
  position: absolute;
  opacity: 0;
}

.customSwitch input[type=radio] + label {
  background-color: #ffffff;
  background-color: var(--white);
  border: 1px solid #707070;
  border: 1px solid var(--darkgrey);
}

.customSwitchinput[type=radio]:hover + label{
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
}

.customSwitch input[type=radio]:focus + label {
  border: 1px solid #26b2e7;
  border: 1px solid var(--lightblue);
  outline: 3px solid #26b2e7;
  outline: 3px solid var(--lightblue);
}

.customSwitch input[type=radio]:focus + label span {
  color: #ffffff;
  color: var(--white);
}

.customSwitch input[type=radio]:checked + label {
  position: relative;
  background: #015488;
  background: var(--darkblue);
  color: #ffffff;
  color: var(--white);
}

.customSwitch input[type=radio]:checked + label span {
  color: #ffffff;
  color: var(--white);
}

/* Select Menu */

.ui.fluid.dropdown .text {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 1em !important;
  font-weight: 600 !important;
  color:#707070 !important;
  color:var(--darkgrey) !important;
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

.ui.selection.dropdown {
  width: 100% !important;
  border: 1px solid #707070 !important;
  border: 1px solid var(--darkgrey) !important;
  border-radius: 0 !important;
}

.ui.selection.dropdown input.search {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 1em !important;
  font-weight: 600 !important;
  color:#707070 !important;
  color:var(--darkgrey) !important;
}

.ui.selection.dropdown:hover {
  border: 1px solid #26b2e7 !important;
  border: 1px solid var(--lightblue) !important;
}

.ui.selection.dropdown.active.visible {
  border: 1px solid #26b2e7 !important;
  border: 1px solid var(--lightblue) !important;
  border-radius: 0px !important;
  outline: 3px solid #26b2e7;
  outline: 3px solid var(--lightblue);
}

.ui.selection.dropdown .visible.menu.transition {
  margin: 3px 0 0 0 !important;
  border: 1px solid #26b2e7 !important;
  border: 1px solid var(--lightblue) !important;
  border-radius: 0px !important;
}

.ui.selection.dropdown .visible.menu.transition .message {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 1em !important;
  font-weight: 600 !important;
  color:#707070 !important;
  color:var(--darkgrey) !important;
}

.ui.selection.dropdown div.item {
  border: 0 !important;
  border-radius: 0px !important;
  background-color: #ffffff !important;
  background-color: var(--white) !important;
  color: #323232;
  color: var(--black);
}

.ui.selection.dropdown div.item:hover {
  border: 0 !important;
  border-radius: 0px !important;
  background-color: #eeeeee !important;
  background-color: var(--lightgrey) !important;
  color: #323232;
  color: var(--black);
}

.ui.selection.dropdown div.active.selected.item {
  border: 0 !important;
  border-radius: 0px !important;
  background-color: #015488 !important;
  background-color: var(--darkblue) !important;
  color: #ffffff !important;
  color: var(--white) !important;
}

.ui.selection.dropdown div.active.selected.item .text {
  color: #ffffff !important;
  color: var(--white) !important;
}

.test {
  width: 125px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  font-size: 1em;
  line-height: 1.5em;
  color: #707070;
  color: var(--darkgrey);
  text-align: center;
}

.login-warning {
  margin-top: 10px;
  color: red;
}

.not-in-use {
	color: red;
	font-size: 0.75em;
}
